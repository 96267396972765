import * as React from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import { RequiredFieldIcon } from '../../icons';
import './CustomCheckbox.less';

type CustomProps = {
    label: string;
    required?: boolean;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
};

export type CustomCheckboxProps = CustomProps & CheckboxProps;

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props: CustomCheckboxProps) => {
    const { label, required, containerStyle, hasError, getContainerRef, ...checkboxProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-checkbox-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            <label className="custom-checkbox-label">
                {label} {!!required && <RequiredFieldIcon />}
            </label>

            <Checkbox {...checkboxProps} />
        </div>
    );
};

export default CustomCheckbox;
