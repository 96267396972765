import { uniqBy } from 'lodash';
import { CustomSource, CustomSourceOption, CustomSourceData } from '../../custom_shared/types';

export default abstract class SourceModelBase {
    readonly id: string;
    readonly type: string;

    constructor(data: CustomSource) {
        this.id = data.id;
        this.type = data.type;
    }

    protected mapData(data: CustomSourceData[]) {
        return uniqBy(data.map(d => ({ label: d.text, value: d.value, title: d.text })), 'value');
    }

    abstract getSourceOptions(): Promise<CustomSourceOption[]>;
}
