import { gql } from 'apollo-boost';
import {
    PackageStateResult,
    ProjectResult,
    ProjectByIdResult,
    ProjectsByUserResult,
    DefaultProjectResult,
    DefaultProjectRes,
    PackageSetItemModel,
    PackageSetItemsResult,
    SearchPackagesResponse,
    SearchPackagesRequest,
    SearchPackageLinesRequest,
    SearchPackageLinesResult,
} from './types';
import { execQuery } from './DataService';
import security from './SecurityService';
import appClient from './AppClient';

export default class ProjectsService {
    async getProjects(): Promise<ProjectResult[]> {
        var result = await execQuery<ProjectsByUserResult>({
            query: gql`query allProject {
                projectsByUser {
                    id,
                    name,
                    color
                }   
            }`,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.projectsByUser;
    }

    async searchPackages({
        projectIds = [],
        page = 0,
        pageSize = 50,
        searchTerm = null,
        fieldsSearch = null,
        allSources = true,
        orderBy = null,
        distinctBy = null
    }: SearchPackagesRequest) {
        const result = await execQuery<SearchPackagesResponse>({
            query: gql`
                query searchPackagesForProjects(
                    $projectIds: [String]!
                    $searchTerm: String
                    $pageSize: Int!
                    $page: Int = 0
                    $allSources: Boolean
                    $fieldsSearch: Json = null
                    $orderBy: Json = null
                    $distinctBy: String
                ) {
                    searchPackages(
                        projectIds: $projectIds
                        searchTerm: $searchTerm
                        pageSize: $pageSize
                        page: $page
                        allSources: $allSources
                        fieldsSearch: $fieldsSearch
                        orderBy: $orderBy
                        distinctBy: $distinctBy
                    ) {
                        result {
                            id,
                            state,
                            projectId,
                            fileName,
                            userTags,
                            fileSizeBytes,
                            uploadedTime,
                            indexDate,
                            packageSetId,
                            source,
                            packageSet {
                                id,
                                name,
                                packageIds,
                                type,
                                projectId,
                                uploadTime,
                                state
                            }
                        }
                        pageInfo {
                            total
                        }
                    }
                }
            `,
            variables: {
                projectIds,
                searchTerm,
                pageSize,
                page,
                fieldsSearch,
                allSources,
                orderBy,
                distinctBy
            },
            fetchPolicy: 'network-only'
        });

        return {
            packages: result.data.searchPackages.result,
            total: result.data.searchPackages.pageInfo.total
        };
    }

    async getDefaultProject(): Promise<DefaultProjectRes> {
        var result = await execQuery<DefaultProjectResult>({
            query: gql`query defaultProject {
                getDefaultProject {
                    id,
                    defaultProject
                }
            }`,
            fetchPolicy: 'network-only'
        });

        return result.data.getDefaultProject;
    }

    // eslint-disable-next-line max-len
    async getPackages(projectId: string, state: PackageStateResult = PackageStateResult.Ready): Promise<ProjectResult | null> {
        var result = await execQuery<ProjectByIdResult>({
            query: gql`query getPackagesForProject($id:String!) {
                projectById(id: $id) {
                    id,
                    packages(state: ${state}) {
                        id,
                        fileName,
                        filePath,
                        projectId,
                        contentType,
                        state,
                        operationState,
                        fileSizeBytes,
                        uploadedTime,
                        indexDate
                    }
                }   
            }`,
            variables: {
                id: projectId
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.projectById) {
            return null;
        }

        return result.data.projectById;
    }

    handleDownload(id: string, fileType: 'pdf'| 'apkg') {
        let path = encodeURIComponent(id);
        security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            let url = '';
            if (fileType === 'pdf') {
                url = `${process.env.REACT_APP_MANAGE_URL}document/${path}${t}`;
            } else {
                url = `${process.env.REACT_APP_MANAGE_URL}file/${fileType}/${path}${t}`;

            }
            window.open(url);
            return Promise.resolve();
        });
    }

    getPackageAsync(packageId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `packages/${packageId}?includeFields=false`;
        return appClient.get(url);
    }

    uploadPackage(formData: FormData, projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/client/upload`;
        return appClient.post(url, formData);
    }

    uploadPackageSet(formData: FormData, projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/upload-set`;
        return appClient.post(url, formData);
    }

    getAllUsers(includeRoles: boolean = true) {
        const url = process.env.REACT_APP_USERS_URL + `?includeRoles=${includeRoles}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return appClient.get<any>(url);
    }

    async sendPackageViaEmail(id: string, emails: string[], message?: string) {
        var request = {
            emails,
            message
        };

        const url = process.env.REACT_APP_MANAGE_URL + `packages/${id}/send-by-email`;
        return appClient.post(url, request);
    }

    async getPackageSet(packageSetId: string): Promise<PackageSetItemModel[]> {
        var result = await execQuery<PackageSetItemsResult>({
            query: gql`query getTasksData($packageSetId:String) {
                getPackagesFromSet(packageSetId: $packageSetId) {
                    id,
                    fileName,
                    fileSizeBytes,
                    contentType,
                    indexDate
                }
            }`,
            variables: {
                packageSetId
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.getPackagesFromSet;
    }

    async getPackageLines(request: SearchPackageLinesRequest) {
        const { projectId, packageId, searchTerm, pageSize, page, fieldsSearch, searchOptions } = request;

        const result = await execQuery<SearchPackageLinesResult>({
            query: gql`
                query getPackagesForProject(
                    $projId: String!,
                    $pkgId: String,
                    $searchTerm: String,
                    $pageSize: Int!,
                    $page: Int = 0,
                    $fieldsSearch: Json = null,
                    $searchOptions: Json = null
                ) {
                    searchPackageLines(
                        projId: $projId,
                        pkgId: $pkgId,
                        searchTerm: $searchTerm,
                        pageSize: $pageSize,
                        page: $page,
                        fieldsSearch: $fieldsSearch,
                        searchOptions: $searchOptions
                    ) {
                        result {
                            fieldId
                            rowId
                            page
                            x
                            y
                            w
                            h
                            pageWidth
                            pageHeight
                            text
                            normalizedText
                            imagePath
                            hasImage
                            packageId
                            label
                            additionalProperties
                            blockType
                            childFields
                        }
                        pageInfo {
                            total
                        }
                    }
                }
            `,
            variables: {
                projId: projectId,
                pkgId: packageId,
                searchTerm: searchTerm ?? null,
                pageSize,
                page: page ?? 0,
                fieldsSearch: fieldsSearch ?? null,
                searchOptions: searchOptions ?? null
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return null;
        }

        return result.data.searchPackageLines;
    }
}