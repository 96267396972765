import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, FormInstance } from 'antd';
import { useAppStore } from '../../stores';
import './AppHeader.less';

interface Props {
    formRefs: React.MutableRefObject<Map<string, FormInstance<unknown>>>
}

const AppHeader: React.FC<Props> = ({ formRefs }) => {
    const { tab } = useAppStore();

    const onSubmit = () => {
        formRefs.current.forEach(form => {
            form.validateFields();
        });
    };

    return (
        <div className="contract-ingestion-app-header">
            <div className="left-container">
                <span className="package-name">{tab.metadata.packageName as string}</span>
                <span className="application-name">{tab.metadata.applicationData.appName as string}</span>
            </div>

            <div className="right-container">
                <Button type="primary" onClick={onSubmit} style={{ height: 40 }}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default observer(AppHeader);
