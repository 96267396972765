import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input, InputNumber } from 'antd';
import { useAppStore } from '../../stores';
import { DummyFormInputIds } from '../../stores/DummyFormStore';
import './DummyForm.less';

const DummyForm: React.FC = () => {
    const { sessionStore, dummyFormStore, documentVisualStore } = useAppStore();

    const [form] = Form.useForm();

    const [highlightedInputId, setHighlightedInputId] = React.useState('');

    const onFinish = () => {
        dummyFormStore.setValues(form.getFieldsValue());
        sessionStore.updateSession();
    };

    React.useEffect(() => {
        const subject = documentVisualStore.highlightedInputIdSubject.subscribe(inputId =>
            setHighlightedInputId(inputId)
        );

        return () => subject.unsubscribe();
    }, [documentVisualStore.highlightedInputIdSubject]);

    React.useEffect(() => {
        Object.entries(dummyFormStore.values).forEach(([key, value]) => form.setFieldsValue({ [key]: value }));
    }, [form, dummyFormStore.values]);

    return (
        <Form className="dummy-form" layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item label="String Input" name={DummyFormInputIds.Field1StringValue}>
                <Input className={highlightedInputId === DummyFormInputIds.Field1StringValue ? 'highlighted' : ''} />
            </Form.Item>

            <Form.Item label="Number Input" name={DummyFormInputIds.Field1NumberValue}>
                <InputNumber className={highlightedInputId === DummyFormInputIds.Field1StringValue ? 'highlighted' : ''} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" className="form-button" htmlType="submit">
                    Update Session
                </Button>
            </Form.Item>

            <Form.Item>
                <Button type="primary" className="form-button" onClick={() => sessionStore.finishSession()}>
                    Finish Session
                </Button>
            </Form.Item>
        </Form>
    );
};

export default observer(DummyForm);
