import * as React from 'react';
import { Form, FormInstance } from 'antd';
import { InputBaseModel, TabBaseModel } from '../models';
import { TypeUtils } from '../../custom_shared/misc';
import { InstanceUtils } from '../misc';

type FormValues = Record<string, unknown>;

export default (tab: TabBaseModel, addFormRef: (id: string, form: FormInstance) => void) => {
    const [form] = Form.useForm<FormValues>();

    const onValuesChange = (changedValues: FormValues) => {
        Object.keys(changedValues).forEach(inputId => handleInputValuesChange(inputId));
    };

    const resetInputValue = (inputId: string) => {
        const input = tab.getInputById(inputId);

        if (!input) {
            return;
        }

        const value = TypeUtils.isStringArray(form.getFieldValue(inputId)) ? [] : null;

        input.setValue(value);

        form.setFieldsValue({ [`${inputId}`]: value });
    };

    const updateChildInputValues = (input: InputBaseModel) => {
        if (!InstanceUtils.isInputWithSource(input.childInput)) {
            return;
        }

        resetInputValue(input.childInput.id);

        input.childInput.sourceProvider.getSourceOptionsByParent(form.getFieldValue(input.id));

        updateChildInputValues(input.childInput);
    };

    const handleInputValuesChange = (inputId: string) => {
        const input = tab.getInputById(inputId);

        if (!input) {
            return;
        }

        const value = form.getFieldValue(inputId);

        input.setValue(value);

        updateChildInputValues(input);
    };

    React.useEffect(() => {
        addFormRef(tab.id, form);
    }, [tab.id, form, addFormRef]);

    React.useEffect(() => {
        form.resetFields();
    }, [form, tab.inputs]);

    return {
        form,
        onValuesChange
    };
};
