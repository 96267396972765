import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { findLast } from 'lodash';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse, AddDeductionButton } from '../../components';
import { StyleUtils } from '../../misc';
import { TabProps } from '../../componentTypes';
import {
    CommissionInput,
    BrokerageInput,
    TaxOnPremiumInput,
    DeductionTypeInput,
    DeductionValueInput
} from '../../tab_inputs/DeductionsTabInputs';
import { Utils } from '../../misc';
import { InputBaseModel } from '../../models';
import { useTabForm } from '../../hooks';

const addDeductionButtonKey = 'add-deduction-button';

const InputIds = {
    Commission: 'Commission',
    Brokerage: 'Brokerage',
    TaxOnPremium: 'TaxOnPremium',
    DeductionType: 'DeductionType',
    DeductionValue: 'DeductionValue'
} as const;

const InputComponents = {
    [InputIds.Commission]: CommissionInput,
    [InputIds.Brokerage]: BrokerageInput,
    [InputIds.TaxOnPremium]: TaxOnPremiumInput,
    [InputIds.DeductionType]: DeductionTypeInput,
    [InputIds.DeductionValue]: DeductionValueInput
} as const;

const isDeductionInput = (input: InputBaseModel) => {
    return input.id.startsWith(InputIds.DeductionType) || input.id.startsWith(InputIds.DeductionValue);
};

const DeductionsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const addDeductionButton = React.useMemo(() => <AddDeductionButton key={addDeductionButtonKey} tab={tab} />, [tab]);

    const componentRows = React.useMemo(() => {
        const { basicInputs, deductionInputs } = tab.inputs.reduce<{
            basicInputs: InputBaseModel[];
            deductionInputs: InputBaseModel[]
        }>(
            (acc, input) => {
                if (isDeductionInput(input)) {
                    acc.deductionInputs.push(input);
                } else {
                    acc.basicInputs.push(input);
                }

                return acc;
            },
            { basicInputs: [], deductionInputs: [] }
        );

        const basicInputRows = Utils.groupArray(basicInputs, 4).map(inputGroup =>
            inputGroup.map(input => (
                <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
            ))
        );

        const deductionInputRows = Utils.groupArray(deductionInputs, 2).map(inputGroup =>
            inputGroup.map(input => (
                <InputRenderer
                    key={input.id}
                    input={input}
                    component={input.id.startsWith(InputIds.DeductionType) ? DeductionTypeInput : DeductionValueInput}
                />
            ))
        );

        const rows = [...basicInputRows, ...deductionInputRows];

        const lastRow = findLast(rows, row => row.length < 4);

        if (lastRow) {
            lastRow.push(addDeductionButton);
        } else {
            rows.push([addDeductionButton]);
        }

        return rows;
    }, [tab.inputs, addDeductionButton]);

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                {componentRows.map((componentRow, index) => (
                    <Row key={index} gutter={10}>
                        {componentRow.map(component => (
                            <Col key={component.key} style={StyleUtils.getTabColumnStyle(25)}>
                                {component}
                            </Col>
                        ))}
                    </Row>
                ))}
            </TabCollapse>
        </Form>
    );
};

export default observer(DeductionsTabRenderer);
