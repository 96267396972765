import { SectionsTabDto } from '../types';
import { TabBaseModel, SectionModel, SourceModelBase } from '.';

export default class SectionsTabModel extends TabBaseModel {
    readonly numberOfSections: number;

    readonly sections: SectionModel[] = [];

    constructor(data: SectionsTabDto, sources: SourceModelBase[]) {
        super(data);

        this.numberOfSections = data.numberOfSections;
        this.sections = data.sections.map(section => new SectionModel(this, section, sources));
    }

    get inputs() {
        return this.sections.flatMap(section => section.inputs);
    }

    get dto(): SectionsTabDto {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            numberOfSections: this.numberOfSections,
            sections: this.sections.map(section => section.dto)
        };
    }
}
