/* eslint-disable @typescript-eslint/member-ordering */
import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { WorkflowInstanceStore, ProjectsStore, TabsStore, UserProfileStore } from '../../common/stores';
import { ApplicationSession, SessionStates } from '../../common/services/types';
import { ApplicationSessionChanges } from '../types';
import PushClient from '../../common/services/ManagePushClient';

export default class SessionsVisualStore {
    applicationSessions: ApplicationSession[] = [];

    searchTerm: string = '';

    sessionsLoading: boolean = true;

    loadForCurrentUserOnly: boolean;

    get filteredSessions() {
        const applications = this.applicationSessions.filter(a => (['Aborted', 'Finished', 'Archived'] as SessionStates[]).includes(a.state));
        if (!this.searchTerm || this.searchTerm.trim() === '') {
            return applications;
        } else {
            return applications.filter(a =>
                (a.applicationName && a.applicationName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0)
                || (a.projectName && a.projectName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0)
                || (a.packageName && a.packageName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0));
        }
    }

    get allUsers() {
        return this.projectsStore.allUsers;
    }

    constructor(
        private workflowInstanceStore: WorkflowInstanceStore, 
        private projectsStore: ProjectsStore, 
        private tabsStore: TabsStore, 
        private userProfileStore: UserProfileStore
    ) {
        makeObservable(this, {
            applicationSessions: observable,
            searchTerm: observable,
            sessionsLoading: observable,
            loadForCurrentUserOnly: observable,
            filteredSessions: computed,
            allUsers: computed,
            setSearchTerm: action,
            loadApplicationSessions: action.bound,
            updateSessionState: action.bound,
            moveSessionToArchive: action,
            switchToSession: action
        });

        this.projectsStore.sessionChanges.subscribe((s) => {
            this.updateSessionState(s); 
        });
        PushClient.ConnectionStatusSubject.subscribe((connected) => {
            if (connected && PushClient.ReconnectAttemptCount > 0) {                
                this.loadApplicationSessions(this.loadForCurrentUserOnly);
            }
        });
    }

    setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

    async loadApplicationSessions(currentUserOnly: boolean = false) {
        await this.projectsStore.loadProjectsPromise;
        if (this.projectsStore.projects) {
            const projects = this.projectsStore.projects.map(p => p.id);
            let res = await this.workflowInstanceStore.loadApplicationSessions(projects);

            if (currentUserOnly) {
                res = res.filter(s => s.userId === this.userProfileStore.currentUserProfileInfo.userId);
            }

            runInAction(() => { 
                this.loadForCurrentUserOnly = currentUserOnly;
                this.applicationSessions = res; 
            });
        } else {
            runInAction(() => { 
                this.applicationSessions = []; 
            });
        }

        runInAction(() => { 
            this.sessionsLoading = false; 
        });
    }

    updateSessionState(updateModel: ApplicationSessionChanges) {
        let sessionToUpdate = this.applicationSessions.find(a => a.id === updateModel.id);
        if (sessionToUpdate) {
            runInAction(() => {
                const index = this.applicationSessions.indexOf(sessionToUpdate!);
                let newList = this.applicationSessions.slice();
                newList[index].state = updateModel.state;
                newList[index].error = updateModel.error || '';
                newList[index].runtimeSessionId = updateModel.runtimeSessionId;
                this.applicationSessions = newList;
            });
        } else {
            this.loadApplicationSessions(this.loadForCurrentUserOnly);
        }
    }

    startSessionFromExisting(session: ApplicationSession) {
        if (session.packageId) {
            this.workflowInstanceStore.startSession(session.packageId, session.applicationDefinitionId, session.packageName, session.applicationExtension);
        } else if (session.packageSetId) {
            this.workflowInstanceStore.startSessionForPackageSet(
                session.packageSetId, 
                session.applicationDefinitionId, 
                session.applicationExtension, 
                session.packageName, 
                undefined, 
                session.isBulkSession
            );
        }
    }

    moveSessionToArchive(sessionId: string) {
        const index = this.applicationSessions.map(x => x.id).indexOf(sessionId);
        this.applicationSessions[index].state = 'Archived';
    }

    async switchToSession(sessionObj: unknown) {
        const session = sessionObj as ApplicationSession;
        await this.tabsStore.addTab({
            id: session.id,
            title: session.packageName,
            isSelected: true,
            type: session.isBulkSession ? 'multidocument' : 'document',
            metadata: {
                packageId: session.packageId,
                sessionId: session.id,
                packageName: session.packageName,
                dynamicUI: undefined,
                sessionError: session.error,
                sessionState: session.state,
                runtimeSessionId: session.runtimeSessionId,
                packageSetId: session.packageSetId,
                projectName: session.projectName,
                projectId: session.projectId,
                isReloadable: session.isReloadable,
                customUI: session.capabilities?.customUI,
                isBulk: session.isBulkSession,
                applicationData: {
                    appName: session.applicationName,
                    appId: session.applicationDefinitionId,
                    appExtension: session.applicationExtension,
                    appSettings: session.applicationSettings,
                    iotaApplication: session.iotaApplication
                }
            }
        });
    }
}