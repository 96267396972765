import { observable, action, reaction, makeObservable } from 'mobx';
import { Disposer } from '../../custom_shared/misc';
import { CustomLinkedSource, CustomStaticSource } from '../../custom_shared/types';
import { StaticSourceModel, LinkedSourceModel, SourceModelBase } from '../models';
import { TabModel } from '../../common/types/TabModel';
import { TabApplicationData } from '../../common/services/types';
import { SessionService } from '../../common/services';
import { WidgetValuesProvider } from '../../common/services/WidgetValuesProvider';
import SessionStore from './SessionStore';

export default class SourceStore extends Disposer {
    sources: SourceModelBase[] = [];

    constructor(
        private readonly tab: TabModel,
        private readonly sessionStore: SessionStore,
        private readonly sessionService: SessionService
    ) {
        super();

        makeObservable(this, {
            sources: observable,
            createSources: action
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (!this.sessionStore.inProgress) {
                        this.createSources();
                    }
                }
            )
        );
    }

    createSources() {
        if (!this.sessionStore.response) {
            return;
        }

        this.sources = this.sessionStore.response.sources.map(source => {
            if (source.link) {
                const widgetValuesProvider = new WidgetValuesProvider(this.sessionService, source.link, this.sessionId, this.applicationData);
                return new LinkedSourceModel(source as CustomLinkedSource, widgetValuesProvider);
            }

            return new StaticSourceModel(source as CustomStaticSource);
        });
    }

    get sessionId() {
        return this.tab.metadata.sessionId as string;
    }

    get applicationData() {
        return this.tab.metadata.applicationData as TabApplicationData;
    }

    dispose() {
        this.disposeReactions();
    }
}
