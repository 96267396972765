import * as React from 'react';
import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useSelectedElementStatus } from '../../hooks';
import { RequiredFieldIcon } from '../../icons';
import './CustomSelectMultiple.less';

type CustomProps = {
    label: string;
    required?: boolean;
    mode?: 'multiple' | 'tags';
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
};

export type CustomSelectMultipleProps = CustomProps & Omit<SelectProps<SelectValue>, 'placeholder'>;

const CustomSelectMultiple: React.FC<CustomSelectMultipleProps> = (props: CustomSelectMultipleProps) => {
    const {
        label,
        required,
        maxTagCount = 'responsive',
        size = 'small',
        mode = 'multiple',
        topRightNode,
        containerStyle,
        hasError,
        getContainerRef,
        ...selectProps
    } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { hasSelectedElement } = useSelectedElementStatus(containerRef, '.ant-select-selection-item');

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-select-multiple-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <Select
                className={`custom-select-multiple ${hasSelectedElement ? 'not-empty' : ''}`}
                size={size}
                mode={mode}
                maxTagCount={maxTagCount}
                {...selectProps}
            />

            <label className="custom-input-label">
                {label} {!!required && <RequiredFieldIcon />}
            </label>
        </div>
    );
};

export default CustomSelectMultiple;
