import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import {
    TitleInput,
    CreateProgramInput,
    MainClassInput,
    ClassInput,
    SubClassInput,
    TypeOfParticipationInput,
    BusinessTypeInput,
    UnderwritingYearInput,
    PeriodStartInput,
    PeriodEndInput,
    CountryGroupsInput,
    CountriesInput,
    LifecycleInput,
    CurrencyInput,
    CedentInput,
    InsuredInput,
    BrokerInput,
    ReinsurerInput,
    ReportUnitsInput
} from '../../tab_inputs/GeneralInformationTabInputs';
import { TabProps } from '../../componentTypes';
import { StyleUtils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    Title: 'Title',
    MainClass: 'MainClass',
    Class: 'Class',
    SubClass: 'SubClass',
    TypeOfParticipation: 'TypeOfParticipation',
    Lifecycle: 'Lifecycle',
    UnderwritingYear: 'UnderwritingYear',
    PeriodStart: 'PeriodStart',
    PeriodEnd: 'PeriodEnd',
    Currency: 'Currency',
    Countries: 'Countries',
    CountryGroups: 'CountryGroups',
    Cedent: 'Cedent',
    Reinsurer: 'Reinsurer',
    CreateProgram: 'CreateProgram',
    ReportUnits: 'ReportUnits',
    BusinessType: 'BusinessType',
    Insured: 'Insured',
    Broker: 'Broker'
} as const;

const GeneralInformationTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const inputs = React.useMemo(
        () => ({
            titleInput: tab.getInputById(InputIds.Title),
            createProgramInput: tab.getInputById(InputIds.CreateProgram),
            mainClassInput: tab.getInputById(InputIds.MainClass),
            classInput: tab.getInputById(InputIds.Class),
            subClassInput: tab.getInputById(InputIds.SubClass),
            businessTypeInput: tab.getInputById(InputIds.BusinessType),
            typeOfParticipationInput: tab.getInputById(InputIds.TypeOfParticipation),
            underwritingYearInput: tab.getInputById(InputIds.UnderwritingYear),
            periodStartInput: tab.getInputById(InputIds.PeriodStart),
            periodEndInput: tab.getInputById(InputIds.PeriodEnd),
            countryGroupsInput: tab.getInputById(InputIds.CountryGroups),
            countriesInput: tab.getInputById(InputIds.Countries),
            lifecycleInput: tab.getInputById(InputIds.Lifecycle),
            currencyInput: tab.getInputById(InputIds.Currency),
            insuredInput: tab.getInputById(InputIds.Insured),
            reinsurerInput: tab.getInputById(InputIds.Reinsurer),
            reportUnitsInput: tab.getInputById(InputIds.ReportUnits),
            cedentInput: tab.getInputById(InputIds.Cedent),
            brokerInput: tab.getInputById(InputIds.Broker)
        }),
        [tab]
    );

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                <Row gutter={10}>
                    <Col style={StyleUtils.getTabColumnStyle(40)}>
                        <InputRenderer input={inputs.titleInput} component={TitleInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.createProgramInput} component={CreateProgramInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(40)} />
                </Row>

                <Row gutter={10}>
                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.mainClassInput} component={MainClassInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(40)}>
                        <InputRenderer input={inputs.classInput} component={ClassInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(40)}>
                        <InputRenderer input={inputs.subClassInput} component={SubClassInput} />
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.businessTypeInput} component={BusinessTypeInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.typeOfParticipationInput} component={TypeOfParticipationInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.underwritingYearInput} component={UnderwritingYearInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.periodStartInput} component={PeriodStartInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.periodEndInput} component={PeriodEndInput} />
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.countryGroupsInput} component={CountryGroupsInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(40)}>
                        <InputRenderer input={inputs.countriesInput} component={CountriesInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.lifecycleInput} component={LifecycleInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.currencyInput} component={CurrencyInput} />
                    </Col>
                </Row>

                <Row gutter={10}>
                    {inputs.insuredInput && (
                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.insuredInput} component={InsuredInput} />
                        </Col>
                    )}

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.reinsurerInput} component={ReinsurerInput} />
                    </Col>

                    <Col style={StyleUtils.getTabColumnStyle(20)}>
                        <InputRenderer input={inputs.reportUnitsInput} component={ReportUnitsInput} />
                    </Col>

                    {inputs.cedentInput && (
                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.cedentInput} component={CedentInput} />
                        </Col>
                    )}

                    {inputs.brokerInput && (
                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.brokerInput} component={BrokerInput} />
                        </Col>
                    )}
                </Row>
            </TabCollapse>
        </Form>
    );
};

export default observer(GeneralInformationTabRenderer);
