import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelectWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModelWithSource } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithSource } from '../../componentTypes';

const config = {
    label: 'Cedent',
    required: true
};

const CedentInput: InputWithSource = ({ input }) => {
    return (
        <CustomFormItem
            name={input.id}
            initialValue={input.value ?? null}
            required={config.required}
            message={ValidationMessages.requiredInput(config.label)}
        >
            <CustomSelectWrapper
                input={input}
                label={config.label}
                options={input.sourceProvider.options}
                topRightNode={<InputActions input={input} />}
                required={config.required}
            />
        </CustomFormItem>
    );
};

CedentInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(CedentInput);
