import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import { StyleUtils } from '../../misc';
import { TabProps } from '../../componentTypes';
import {
    CurrencyTypeInput,
    CalculationRuleInput,
    ReinstatementsTypeInput,
    CountOfReinstatementsInput,
    PaymentRuleInput,
    AmountPercentInput,
    TimePercentInput,
    BrokerageTypeInput,
    BrokeragePercentInput
} from '../../tab_inputs/ReinstatementsTabInputs';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    CurrencyType: 'CurrencyType',
    CalculationRule: 'CalculationRule',
    ReinstatementsType: 'ReinstatementsType',
    CountOfReinstatements: 'CountOfReinstatements',
    PaymentRule: 'PaymentRule',
    AmountPercent: 'AmountPercent',
    TimePercent: 'TimePercent',
    BrokerageType: 'BrokerageType',
    BrokeragePercent: 'BrokeragePercent'
} as const;

const InputComponents = {
    [InputIds.CurrencyType]: CurrencyTypeInput,
    [InputIds.CalculationRule]: CalculationRuleInput,
    [InputIds.ReinstatementsType]: ReinstatementsTypeInput,
    [InputIds.CountOfReinstatements]: CountOfReinstatementsInput,
    [InputIds.PaymentRule]: PaymentRuleInput,
    [InputIds.AmountPercent]: AmountPercentInput,
    [InputIds.TimePercent]: TimePercentInput,
    [InputIds.BrokerageType]: BrokerageTypeInput,
    [InputIds.BrokeragePercent]: BrokeragePercentInput
} as const;

const ReinstatementsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const componentRows = React.useMemo(
        () =>
            Utils.groupArray(tab.inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        [tab.inputs]
    );

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                {componentRows.map((componentRow, index) => (
                    <Row key={index} gutter={10}>
                        {componentRow.map(component => (
                            <Col key={component.key} style={StyleUtils.getTabColumnStyle(25)}>
                                {component}
                            </Col>
                        ))}
                    </Row>
                ))}
            </TabCollapse>
        </Form>
    );
};

export default observer(ReinstatementsTabRenderer);
