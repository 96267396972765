import { InputModelWithSource, LinkedSourceModel } from '../models';

export default class InstanceUtils {
    static isInputWithSource(value: unknown): value is InputModelWithSource {
        return value instanceof InputModelWithSource;
    }

    static isLinkedSource(value: unknown): value is LinkedSourceModel {
        return value instanceof LinkedSourceModel;
    }
}
