import { makeObservable, observable, action } from 'mobx';
import { TabBaseDto } from '../types';
import InputBaseModel from './InputBaseModel';

export default abstract class TabBaseModel {
    readonly id: string;

    readonly type: string;

    readonly name: string;

    expanded: boolean = true;

    constructor(data: TabBaseDto) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;

        makeObservable(this, {
            expanded: observable,
            setExpanded: action.bound,
            toggleExpanded: action.bound
        });
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }

    toggleExpanded() {
        this.setExpanded(!this.expanded);
    }

    getInputById(id: string) {
        return this.inputs.find(input => input.id === id);
    }

    abstract get inputs(): InputBaseModel[];

    abstract get dto(): TabBaseDto;
}
