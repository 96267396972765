import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import { StyleUtils } from '../../misc';
import { TabProps } from '../../componentTypes';
import {
    PremiumEntryCalculationMethodInput,
    PremiumEntryPercentInput,
    PremiumEntryPercentOfInput,
    PremiumWithdrawalCalculationMethodInput,
    PremiumWithdrawalPercentInput,
    PremiumWithdrawalPercentOfInput,
    LossEntryCalculationMethodInput,
    LossEntryPercentInput,
    LossWithdrawalCalculationMethodInput,
    LossWithdrawalPercentInput
} from '../../tab_inputs/PortfolioConditionsTabInputs';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    PremiumEntryCalculationMethod: 'PremiumEntryCalculationMethod',
    PremiumEntryPercent: 'PremiumEntryPercent',
    PremiumEntryPercentOf: 'PremiumEntryPercentOf',
    PremiumWithdrawalCalculationMethod: 'PremiumWithdrawalCalculationMethod',
    PremiumWithdrawalPercent: 'PremiumWithdrawalPercent',
    PremiumWithdrawalPercentOf: 'PremiumWithdrawalPercentOf',
    LossEntryCalculationMethod: 'LossEntryCalculationMethod',
    LossEntryPercent: 'LossEntryPercent',
    LossWithdrawalCalculationMethod: 'LossWithdrawalCalculationMethod',
    LossWithdrawalPercent: 'LossWithdrawalPercent'
} as const;

const InputComponents = {
    [InputIds.PremiumEntryCalculationMethod]: PremiumEntryCalculationMethodInput,
    [InputIds.PremiumEntryPercent]: PremiumEntryPercentInput,
    [InputIds.PremiumEntryPercentOf]: PremiumEntryPercentOfInput,
    [InputIds.PremiumWithdrawalCalculationMethod]: PremiumWithdrawalCalculationMethodInput,
    [InputIds.PremiumWithdrawalPercent]: PremiumWithdrawalPercentInput,
    [InputIds.PremiumWithdrawalPercentOf]: PremiumWithdrawalPercentOfInput,
    [InputIds.LossEntryCalculationMethod]: LossEntryCalculationMethodInput,
    [InputIds.LossEntryPercent]: LossEntryPercentInput,
    [InputIds.LossWithdrawalCalculationMethod]: LossWithdrawalCalculationMethodInput,
    [InputIds.LossWithdrawalPercent]: LossWithdrawalPercentInput
} as const;

const PortfolioConditionsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const componentRows = React.useMemo(
        () =>
            Utils.groupArray(tab.inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        [tab.inputs]
    );

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                {componentRows.map((componentRow, index) => (
                    <Row key={index} gutter={10}>
                        {componentRow.map(component => (
                            <Col key={component.key} style={StyleUtils.getTabColumnStyle(25)}>
                                {component}
                            </Col>
                        ))}
                    </Row>
                ))}
            </TabCollapse>
        </Form>
    );
};

export default observer(PortfolioConditionsTabRenderer);
