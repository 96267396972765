import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import { StyleUtils } from '../../misc';
import { TabProps } from '../../componentTypes';
import {
    ShareOfferInput,
    ShareWrittenInput,
    ShareSignedInput,
    CessionPercentInput
} from '../../tab_inputs/ShareTabInputs';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    ShareOffer: 'ShareOffer',
    ShareWritten: 'ShareWritten',
    ShareSigned: 'ShareSigned',
    CessionPercent: 'CessionPercent'
} as const;

const InputComponents = {
    [InputIds.ShareOffer]: ShareOfferInput,
    [InputIds.ShareWritten]: ShareWrittenInput,
    [InputIds.ShareSigned]: ShareSignedInput,
    [InputIds.CessionPercent]: CessionPercentInput
} as const;

const ShareTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const componentRows = React.useMemo(
        () =>
            Utils.groupArray(tab.inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        [tab.inputs]
    );

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                {componentRows.map((componentRow, index) => (
                    <Row key={index} gutter={10}>
                        {componentRow.map(component => (
                            <Col key={component.key} style={StyleUtils.getTabColumnStyle(25)}>
                                {component}
                            </Col>
                        ))}
                    </Row>
                ))}
            </TabCollapse>
        </Form>
    );
};

export default observer(ShareTabRenderer);
