import { ContractsIngestionRuntimeInputDto } from '../types';
import { InputBaseModel, SourceModelBase, TabBaseModel } from '.';
import { SourceProvider } from '../misc';

export default class InputModelWithSource extends InputBaseModel {
    readonly sourceProvider: SourceProvider;

    constructor(tab: TabBaseModel, data: ContractsIngestionRuntimeInputDto, source: SourceModelBase) {
        super(tab, data);

        this.sourceProvider = new SourceProvider(source);
    }
}
