import * as React from 'react';
import './ButtonWithIcon.less';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    icon: React.ReactNode
}

const ButtonWithIcon: React.FC<Props> = ({ label, icon, ...rest }: Props) => {
    return (
        <div className="button-with-icon" {...rest}>
            {icon}
            <span>{label}</span>
        </div>
    );
};

export default ButtonWithIcon;
