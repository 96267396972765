/* eslint-disable max-len */
import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
    color?: string
}

const RequiredFieldIcon: React.FC<Props> = ({ width = 6, height = 12, color = '#F5222D', style = {}, ...props }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 5 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer', ...style }}
        {...props}
    >
        <path
            d="M1.12988 4.54297L2.11133 3.19531L0.636719 2.75586L0.866211 2.02344L2.34082 2.56543L2.29688 0.885742H3.03906L2.99023 2.59473L4.44531 2.05273L4.66992 2.7998L3.1709 3.24414L4.13281 4.56738L3.52734 5.02148L2.62402 3.61523L1.74023 4.9873L1.12988 4.54297Z"
            fill={color}
        />
    </svg>
);

export default RequiredFieldIcon;
