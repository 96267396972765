import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomCheckbox, CustomCheckboxProps } from '../../../custom_shared/components';
import { WithInput } from '../../types';
import { useInputHighlighting } from '../../hooks';

type Props = WithInput<CustomCheckboxProps>;

const CustomCheckboxWrapper: React.FC<Props> = (props: Props) => {
    const { input, ...rest } = props;

    const [containerRef, setContainerRef] = React.useState<React.RefObject<HTMLDivElement> | null>(null);

    const { containerStyle } = useInputHighlighting(input, containerRef);

    return <CustomCheckbox containerStyle={containerStyle} getContainerRef={setContainerRef} {...rest} />;
};

export default observer(CustomCheckboxWrapper);
