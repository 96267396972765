import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';
import { GenericSelect } from '../../components';
import { useAppStore } from '../../stores';

const config = {
    label: 'Premium Entry Calculation Method'
};

const PremiumEntryCalculationMethodInput: InputWithSource = ({ input }) => {
    const { actionStore } = useAppStore();

    return (
        <GenericSelect
            label={config.label}
            input={input}
            onChange={() => actionStore.rebuildTabs()}
            allowClear
        />
    );
};

PremiumEntryCalculationMethodInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(PremiumEntryCalculationMethodInput);
