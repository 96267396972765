import { SectionDto } from '../types';
import { SourceModelBase, InputBaseModel, TabBaseModel } from '.';
import { InputFactory } from '../misc';

export default class SectionModel {
    readonly name: string;

    readonly inputs: InputBaseModel[] = [];

    constructor(tab: TabBaseModel, data: SectionDto, sources: SourceModelBase[]) {
        this.name = data.name;
        this.inputs = data.inputs.map(input => InputFactory.createInput(tab, input, sources));
    }

    get dto(): SectionDto {
        return {
            name: this.name,
            inputs: this.inputs.map(input => input.dto)
        };
    }
}
