import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { PlusIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import { TabBaseModel } from '../../models';
import { ButtonWithIcon } from '../../../custom_shared/components';

interface Props {
    tab: TabBaseModel
}

const AddDeductionButton: React.FC<Props> = ({ tab }: Props) => {
    const { actionStore } = useAppStore();

    return (
        <ButtonWithIcon
            label="Add deduction"
            icon={<PlusIcon />}
            onClick={() => actionStore.addDeduction({ tabId: tab.id })}
        />
    );
};

export default observer(AddDeductionButton);
