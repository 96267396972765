import * as React from 'react';

export default (ref: React.RefObject<HTMLDivElement>) => {
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        const setInitialSize = () => {
            if (ref.current) {
                setHeight(ref.current.clientHeight);
                setWidth(ref.current.clientWidth);
            }
        };

        setInitialSize();

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setHeight(entry.target.clientHeight);
                setWidth(entry.target.clientWidth);
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref]);

    return {
        height,
        width
    };
};
