import * as React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { RequiredFieldIcon } from '../../icons';
import { useSelectedElementStatus } from '../../hooks';
import './CustomInputNumber.less';

export interface CustomInputNumberProps extends Omit<InputNumberProps, 'placeholder'> {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hideStepperButton?: boolean;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
}

const CustomInputNumber: React.FC<CustomInputNumberProps> = (props: CustomInputNumberProps) => {
    const {
        label,
        required,
        topRightNode,
        containerStyle,
        hideStepperButton,
        hasError,
        getContainerRef,
        ...inputNumberProps
    } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { hasSelectedElement } = useSelectedElementStatus(containerRef, 'input[value]:not([value=""])');

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getClassName = () => {
        let className = 'custom-input-number';

        if (hasSelectedElement) {
            className += ' not-empty';
        }

        if (hideStepperButton) {
            className += ' hide-stepper-button';
        }

        return className;
    };

    const getContainerClassName = () => {
        let className = 'custom-input-number-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <InputNumber className={getClassName()} {...inputNumberProps} />

            <label className="custom-input-label">
                {label} {!!required && <RequiredFieldIcon />}
            </label>
        </div>
    );
};

export default CustomInputNumber;
