import * as React from 'react';
import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import { useSelectedElementStatus } from '../../hooks';
import { RequiredFieldIcon } from '../../icons';
import './CustomDatePicker.less';

type CustomProps = {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
};

export type CustomDatePickerProps = CustomProps & DatePickerProps;

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props: CustomDatePickerProps) => {
    const { label, required, topRightNode, containerStyle, hasError, getContainerRef, ...datePickerProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { hasSelectedElement } = useSelectedElementStatus(containerRef, 'input[title]:not([title=""])');

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-date-picker-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <DatePicker
                className={`custom-date-picker ${hasSelectedElement ? 'not-empty' : ''}`}
                {...datePickerProps}
            />

            <label className="custom-input-label">
                {label} {!!required && <RequiredFieldIcon />}
            </label>
        </div>
    );
};

export default CustomDatePicker;
