import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import { StyleUtils } from '../../misc';
import { TabProps } from '../../componentTypes';
import {
    InstalmentsCountInput,
    InstalmentTypeInput,
    FirstInstalmentCountInput,
    FirstInstalmentPeriodInput,
    SubsequentInstalmentCountInput,
    SubsequentInstalmentPeriodInput
} from '../../tab_inputs/InstalmentsTabInputs';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    InstalmentsCount: 'InstalmentsCount',
    InstalmentType: 'InstalmentType',
    FirstInstalmentCount: 'FirstInstalmentCount',
    FirstInstalmentPeriod: 'FirstInstalmentPeriod',
    SubsequentInstalmentCount: 'SubsequentInstalmentCount',
    SubsequentInstalmentPeriod: 'SubsequentInstalmentPeriod'
} as const;

const InputComponents = {
    [InputIds.InstalmentsCount]: InstalmentsCountInput,
    [InputIds.InstalmentType]: InstalmentTypeInput,
    [InputIds.FirstInstalmentCount]: FirstInstalmentCountInput,
    [InputIds.FirstInstalmentPeriod]: FirstInstalmentPeriodInput,
    [InputIds.SubsequentInstalmentCount]: SubsequentInstalmentCountInput,
    [InputIds.SubsequentInstalmentPeriod]: SubsequentInstalmentPeriodInput
} as const;

const InstalmentsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const componentRows = React.useMemo(
        () =>
            Utils.groupArray(tab.inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        [tab.inputs]
    );

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                {componentRows.map((componentRow, index) => (
                    <Row key={index} gutter={10}>
                        {componentRow.map(component => (
                            <Col key={component.key} style={StyleUtils.getTabColumnStyle(25)}>
                                {component}
                            </Col>
                        ))}
                    </Row>
                ))}
            </TabCollapse>
        </Form>
    );
};

export default observer(InstalmentsTabRenderer);
