import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomInputNumberWrapper, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Deduction'
};

const DeductionValueInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem name={input.id} initialValue={input.value ?? null}>
            <CustomInputNumberWrapper input={input} label={config.label} />
        </CustomFormItem>
    );
};

DeductionValueInput.meta = {
    inputType: InputModel.name
};

export default observer(DeductionValueInput);
