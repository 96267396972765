import { reaction } from 'mobx';
import { TabModel } from '../../common/types/TabModel';
import { RootStore } from '../../common/stores/context';
import { DocumentVisualStore } from '../../documents/stores';
import { DocumentService, SessionService, ProjectsService } from '../../common/services';
import { Disposer } from '../../custom_shared/misc';
import { RuntimeDataStore } from '../../custom_shared/stores';
import SessionStore from './SessionStore';
import SourceStore from './SourceStore';
import TabStore from './TabStore';
import ActionStore from './ActionStore';
import DocumentPreviewStore from './DocumentPreviewStore';
import ImageService from '../../common/services/ImageService';

export default class AppStore extends Disposer {
    readonly sessionStore: SessionStore;
    readonly sourceStore: SourceStore;
    readonly tabStore: TabStore;
    readonly actionStore: ActionStore;
    readonly documentPreviewStore: DocumentPreviewStore;
    readonly documentVisualStore: DocumentVisualStore;
    readonly runtimeDataStore: RuntimeDataStore;

    constructor(readonly tab: TabModel, readonly rootStore: RootStore) {
        super();

        this.runtimeDataStore = new RuntimeDataStore();

        this.sessionStore = new SessionStore(this.tab, this.rootStore.iotaSessionStore);

        this.sourceStore = new SourceStore(this.tab, this.sessionStore, new SessionService());

        this.tabStore = new TabStore(this.sessionStore, this.sourceStore);

        this.actionStore = new ActionStore(this.tabStore, this.sessionStore, this.runtimeDataStore);

        this.documentVisualStore = new DocumentVisualStore(
            this.rootStore.applicationDefinitionsStore,
            new ImageService(),
            this.rootStore.errorStore,
            new DocumentService()
        );

        this.documentPreviewStore = new DocumentPreviewStore(
            this.tab,
            this.tabStore,
            this.documentVisualStore,
            new ProjectsService()
        );

        this.setupSessionStateReaction();
    }

    setupSessionStateReaction() {
        this.reactions.push(
            reaction(
                () => this.sessionStore.isFinished,
                isFinished => {
                    if (isFinished) {
                        this.dispose();
                    }
                }
            )
        );
    }

    removeAppTab() {
        const tabIndex = this.rootStore.tabsStore.tabs.indexOf(this.tab);
        this.rootStore.tabsStore.removeTab(tabIndex);
    }

    dispose() {
        this.sessionStore.dispose();
        this.sourceStore.dispose();
        this.tabStore.dispose();
        this.documentPreviewStore.dispose();
        this.disposeReactions();
        this.removeAppTab();
    }
}
