import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { ProjectsStore, ApplicationDefinitionsStore } from '../../common/stores';
import { Modal, Row, Col, message, Spin, AutoComplete, Button, Input, Tooltip } from 'antd';
import { PackageStateResult, PackageListItemModel, PackageListItemType } from '../../common/services/types';
import Upload, { UploadChangeParam, RcFile } from 'antd/lib/upload';
import PackageList from './PackageList';
import ApplicationsMenu from './ApplicationsMenu';
import ActionsMenu from './ActionsMenu';
import { HomeVisualStore, PreviewsVisualStore } from '../stores';
import { ImagePreviewSize } from '../../documents/types/types';
import errorImg from '../../common/resources/images/image-error.png';
import ImageWithFallback from '../../common/components/ImageWithFallback';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
export const NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT = 475;
const NEW_ANALYSIS_CONTAINER_INITIAL_WIDTH = 621;
type Props = {
    projectsStore: ProjectsStore;
    appStore: ApplicationDefinitionsStore;
    homeStore: HomeVisualStore;
    previewsStore: PreviewsVisualStore;
    visible: boolean;
    setVisibility: (visible: boolean) => void
};

export const NewContractDialog: React.FC<Props> = ({ projectsStore, appStore, previewsStore, homeStore, visible, setVisibility }) => {   
    React.useEffect(() => {
        if (visible && !projectsStore.projects && !projectsStore.isLoading) {
            projectsStore.loadProjects();
        }
        if (!visible) {
            projectsStore.resetNewContractDialogState();
        }
    },              [visible, projectsStore]);

    React.useEffect(() => {
        if (projectsStore.selectedPackageId) {
            const selectedPackage = projectsStore.packageListItems.find(p => p.id === projectsStore.selectedPackageId);

            if (selectedPackage && selectedPackage.type === PackageListItemType.PackageSet) {
                return;
            }

            if (selectedPackage && selectedPackage.state !== PackageStateResult.Ready) {
                previewsStore.setPackagePreviewPending({packageId: projectsStore.selectedPackageId, imageSize: ImagePreviewSize.Large});
            } else if (!previewsStore.largePreviewUrls[projectsStore.selectedPackageId]) {
                previewsStore.setPackagePreviewPending(undefined);
                previewsStore.getPreviews(projectsStore.selectedPackageId, ImagePreviewSize.Large);
            } else {
                previewsStore.setPackagePreviewPending(undefined);
            }
        } else {
            previewsStore.setPackagePreviewPending(undefined);
        }
    },              [previewsStore, projectsStore.packageListItems, projectsStore.selectedPackageId]);

    React.useEffect(() => {
        appStore.loadApplicationsForProject(projectsStore.selectedPackageProject);
    },              [appStore, projectsStore.selectedPackageProject]);

    React.useEffect(() => {
        if (!homeStore.selectedProjectToUpload && projectsStore.currentProjectId) {
            homeStore.setSelectedProjectToUpload(projectsStore.currentProjectId);
        }
    },              [homeStore, homeStore.showProjectSelectionModal, projectsStore.currentProjectId]);

    const modalHeader = () => {
        return (
            <div className="label-with-icon">
                <span>New Analysis</span>
            </div>
        );
    };

    const modalFooter = () => {
        return (
            <Row style={{marginTop: 4}}>
                <Col span={12} style={{ textAlign: 'left' }}>
                    <ActionsMenu projectsStore={projectsStore} homeStore={homeStore}/>
                </Col>
                <Col span={12}>
                    <Button className="light" onClick={() => setVisibility(false)}>Cancel</Button>
                    
                    <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments} entityId={projectsStore.selectedPackageProject}>
                        <ApplicationsMenu homeStore={homeStore} appStore={appStore} projectsStore={projectsStore} resetSearch={resetSearch} />
                    </HasPermission>
                </Col>
            </Row>
        );
    };

    const selectPackage = (pkg: PackageListItemModel, multiSelect: boolean = false) => {
        if (pkg.state !== PackageStateResult.Broken) {
            projectsStore.setSelectedPackageId(pkg.id, multiSelect);
        }
    };

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            // eslint-disable-next-line max-len
            let newPkg = projectsStore.packageListItems.find(p => p.fileName === info.file.name && (p.state === PackageStateResult.Uploading || p.state === PackageStateResult.Importing));
            if (newPkg) {
                selectPackage(newPkg);
            }
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const beforeUpload = (file: RcFile) => {
        if (projectsStore.projects && projectsStore.projects.length > 1) {
            homeStore.setFileToUpload(file);
            homeStore.setShowProjectSelectionModal(true);
            return false;
        } else {
            return true;
        }
    };

    const getSelectedPackagePreview = () => {
        let selectedPackage = projectsStore.packageListItems.find(p => p.id === projectsStore.selectedPackageId);

        if (selectedPackage && selectedPackage.type === PackageListItemType.PackageSet) {
            return (
                <div className="package-set-content">
                    {selectedPackage.packages ? selectedPackage.packages.map(p => (
                        <Tooltip key={p.id} title={p.fileName} placement="right">
                            <div>{p.fileName}</div>
                        </Tooltip>
                    )) : <div>No packages in set</div>}
                </div>
            );
        }

        return (
            <>
                {projectsStore.selectedPackageId
                    ? 
                    projectsStore.selectedPackageId && previewsStore.largePreviewUrls[projectsStore.selectedPackageId]
                        ? (
                            <ImageWithFallback 
                                src={previewsStore.largePreviewUrls[projectsStore.selectedPackageId]}
                                fallbackSrc={errorImg}
                            />
                        )
                        : <Spin data-testid="new-contract-dialog-sping" size="large" />
                    :
                    <div data-testid="document-preview-placeholder" className="document-preview-placeholder">
                        <div className="placeholder-icon-container">
                            <i className="document-placeholder-icon" />
                        </div>
                        <div className="document-placeholder-message">
                        Select a document to get a preview.
                        </div>
                    </div>
                }
            </>
        );
    };

    const clearData = React.useCallback(() => {
        projectsStore.removeStickyPackageItems();
        projectsStore.setSelectedPackageId('');
        projectsStore.setPackagesPage(0);
        projectsStore.setPackageListItems([]);
    }, [projectsStore]);

    const debouncedSearch = React.useMemo(
        () =>
            debounce((updateAutocomplete: boolean = true) => {
                clearData();

                projectsStore.searchPackages();

                if (updateAutocomplete) {
                    projectsStore.searchAutocompletePackages();
                }
            }, 500),
        [projectsStore, clearData]
    );

    const onSearch = React.useCallback(
        (packageSearchTerm?: string, updateAutocomplete: boolean = true) => {
            if (packageSearchTerm !== undefined) {
                projectsStore.setPackageSearchTerm(packageSearchTerm);
            }

            debouncedSearch(updateAutocomplete);
        },
        [debouncedSearch, projectsStore]
    );

    const onSort = () => {
        projectsStore.togglePackageSorting();
        onSearch();
    };

    const resetSearch = React.useCallback(() => {
        clearData();
        onSearch();
    }, [clearData, onSearch]);

    React.useEffect(() => {
        if (!visible) {
            resetSearch();
        }
    }, [visible, resetSearch]);
    
    return (
        <Modal
            className="alpha-new-contract"
            maskClosable={false}
            closable={false}
            visible={visible}
            title={modalHeader()}
            footer={modalFooter()}
            onCancel={() => {
                setVisibility(false); 
            }}
            onOk={() => {
                setVisibility(false); 
            }}
            centered
            destroyOnClose
            width={1065}
        >
            <Row>
                <Col span={14}>
                    <Row className="modal-header-container">
                        <Col span={6} offset={9}>
                            <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments}>
                                <Upload
                                    data-id="new-contract-file-dragger"
                                    data-testid="new-contract-file-dragger"
                                    beforeUpload={beforeUpload}
                                    showUploadList={false}
                                    onChange={onChange}
                                    name="file"
                                    multiple={false}
                                    action={projectsStore.docUploadAction}
                                >
                                    <div className="label-with-icon" style={{cursor: 'pointer', color: '#9BA0AA'}}>
                                        <i className="alpha-icon xs plus" />
                                        <span> Upload file</span>
                                    </div>
                                </Upload>
                            </HasPermission>
                        </Col>
                        <Col span={9} style={{ textAlign: 'right' }}>
                            <span>{projectsStore.packageSortingDirection === 'ASC' ?  
                                <i className="alpha-icon xs sort-arrow-up" style={{verticalAlign: 'middle'}}/> :  
                                <i className="alpha-icon xs sort-arrow-down" style={{verticalAlign: 'middle'}}/>}
                            </span>
                            <span style={{color: '#9BA0AA'}}>Sorted by&nbsp;</span>
                            <div
                                data-id="new-contract-sort-link-button"
                                className="label link analysis-sort"
                                onClick={onSort}
                            >
                                {projectsStore.packageSorting}
                            </div>
                        </Col>
                    </Row>
                    <Row className="modal-controls-container">
                        <Col span={24}>
                            <AutoComplete
                                style={{width: '100%', height: 42}}
                                options={projectsStore.packagesForAutocomplete && projectsStore.packagesForAutocomplete.length 
                                    ? projectsStore.packagesForAutocomplete.map((s, i) => {
                                        return {label: s.fileName, value: s.fileName, key: s.fileName + i}; 
                                    }) 
                                    : []}
                                onSearch={onSearch}
                                onSelect={value => onSearch(value, false)}
                                value={projectsStore.packageSearchTerm}
                            >
                                <Input
                                    data-id="new-contract-search-input"
                                    className="alpha-search-input"
                                    placeholder="Search by name, tags, etc..."
                                    onPressEnter={() => onSearch()}
                                    value={projectsStore.packageSearchTerm}
                                    addonAfter={
                                        <>
                                            <i 
                                                className={'alpha-icon xs circle-cross clear-search'} 
                                                style={
                                                    !projectsStore.packageSearchTerm
                                                        ? {
                                                            visibility: 'hidden',
                                                            verticalAlign: 'middle',
                                                            marginRight: 15
                                                        }
                                                        : { verticalAlign: 'middle', marginRight: 15 }
                                                }
                                                onClick={() => onSearch('')}
                                            />
                                            <i 
                                                className="alpha-icon search xs" 
                                                style={{verticalAlign: 'middle'}} 
                                                onClick={() => onSearch()}/>
                                        </>
                                    }
                                />
                            </AutoComplete>
                        </Col>
                    </Row>
                    <div style={{ height: NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT, width: NEW_ANALYSIS_CONTAINER_INITIAL_WIDTH }}>
                        <PackageList projectsStore={projectsStore} appStore={appStore} selectPackage={selectPackage} visible={visible} />
                    </div>
                </Col>
                <Col span={10} className="alpha-document-preview">
                    <Observer>
                        {() => (
                            <div className={`analysis-preview-container ${projectsStore.selectedPackageIds && projectsStore.selectedPackageIds.length > 1 ?  'multiple' : ''}`}>
                                {getSelectedPackagePreview()}
                            </div>
                        )}
                    </Observer>
                    <div className="file-info">
                        {projectsStore.selectedPackageName && projectsStore.selectedPackageName.split('name-separator').map(
                            (x, i) => {
                                let name = '';
                                if (i === 1) {
                                    name = 'second-name';
                                }
                                return <span className={name}  key={i}>{x}</span>;
                            })}
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default observer(NewContractDialog);