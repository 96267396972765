import * as React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { SessionsVisualStore } from '.';
import { useStore } from '../../common/stores/context';
import { WorkflowInstanceStore, ProjectsStore, TabsStore, UserProfileStore } from '../../common/stores';

function createStore(wf: WorkflowInstanceStore, projects: ProjectsStore, tabs: TabsStore, userProfileStore: UserProfileStore) {    
    const sessionsVisualStore = new SessionsVisualStore(wf, projects, tabs, userProfileStore);

    return {
        sessionsVisualStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const SessionsStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalStore(() => createStore(root.workflowInstance, root.projectsStore, root.tabsStore, root.userProfileStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useSessionsStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};