import appClient from '../../common/services/AppClient';
import { CustomSessionState, CustomSessionRuntimeData } from '../types';

export default class CustomSessionService {
    getSessionState<TResponse>(sessionExtension: string, sessionId: string) {
        const url = process.env.REACT_APP_IOTA_URL + `runtime/${sessionExtension}/${sessionId}`;
        return appClient.get<CustomSessionState<TResponse>>(url);
    }

    continueSession(sessionExtension: string, sessionId: string, action: string, inputs: CustomSessionRuntimeData) {
        const url = process.env.REACT_APP_IOTA_URL + `runtime/${sessionExtension}/${sessionId}`;
        return appClient.post(url, { inputs, action });
    }
}
