import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd';
import { useAppStore } from '../../stores';
import { AppHeader, TabsRenderer, DocumentPreview } from '../../components';
import './ContractIngestionAppScreen.less';

const ContractIngestionAppScreen: React.FC = () => {
    const { sessionStore } = useAppStore();

    const formRefs = React.useRef<Map<string, FormInstance>>(new Map());

    const addFormRef = React.useCallback((id: string, form: FormInstance) => {
        formRefs.current.set(id, form);
    }, []);

    React.useEffect(() => {
        sessionStore.getSession();
    }, [sessionStore]);

    return (
        <div className="contract-ingestion-app-screen">
            <AppHeader formRefs={formRefs} />
            <TabsRenderer addFormRef={addFormRef} />
            <DocumentPreview />
        </div>
    );
};

export default observer(ContractIngestionAppScreen);
