import { action, makeObservable, observable, reaction } from 'mobx';
import { first } from 'lodash';
import { Disposer } from '../../custom_shared/misc';
import { TabBaseModel } from '../models';
import { TabFactory, InstanceUtils } from '../misc';
import SessionStore from './SessionStore';
import SourceStore from './SourceStore';

export default class TabStore extends Disposer {
    activeTabId: string = '';

    tabs: TabBaseModel[] = [];

    constructor(private readonly sessionStore: SessionStore, private readonly sourceStore: SourceStore) {
        super();

        makeObservable(this, {
            activeTabId: observable,
            tabs: observable,
            setTabs: action,
            createTabs: action,
            setActiveTabId: action.bound
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (!this.sessionStore.inProgress) {
                        this.createTabs();
                    }
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.tabs,
                () => {
                    if (this.activeTabId && !this.tabs.some(t => t.id === this.activeTabId)) {
                        this.setActiveTabId('');
                    }

                    if (!this.activeTabId && this.firstTab) {
                        this.setActiveTabId(this.firstTab.id);
                    }
                }
            )
        );
    }

    get allInputs() {
        return this.tabs.flatMap(tab => tab.inputs);
    }

    get firstTab() {
        return first(this.tabs);
    }

    setActiveTabId(activeTabId: string) {
        this.activeTabId = activeTabId;
    }

    setTabs(tabs: TabBaseModel[]) {
        this.tabs = tabs;
    }

    createTabs() {
        if (!this.sessionStore.response) {
            return;
        }

        this.setTabs(this.sessionStore.response.tabs.map(tab => TabFactory.createTab(tab, this.sourceStore.sources)));

        this.allInputs.filter(InstanceUtils.isInputWithSource).forEach(input => {
            const fetchSources = input.parentInput
                ? input.sourceProvider.getSourceOptionsByParent(input.parentInput.value)
                : input.sourceProvider.getSourceOptions();

            input.sourceProvider.setLoading(true);

            fetchSources.finally(() => input.sourceProvider.setLoading(false));
        });
    }

    getTabByInputId(inputId: string) {
        return this.tabs.find(tab => tab.inputs.some(input => input.id === inputId));
    }

    dispose() {
        this.disposeReactions();
    }
}
