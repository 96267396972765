import moment from 'moment-timezone';
import { TypeUtils } from '../../custom_shared/misc';
import { Regex } from '.';

export default class Utils {
    static formatDateString(value: unknown) {
        return typeof value === 'string' ? moment.tz(value, 'Europe/London') : null;
    }

    static convertToStringArray(value: unknown): string[] {
        if (!value) {
            return [];
        }

        if (TypeUtils.isStringArray(value)) {
            return value;
        }

        if (typeof value === 'string') {
            return [value];
        }

        return [];
    }

    static groupArray<T>(inputArray: T[], groupSize: number = 4) {
        return inputArray.reduce((acc: T[][], currentValue: T, currentIndex: number) => {
            const groupIndex = Math.floor(currentIndex / groupSize);

            if (!acc[groupIndex]) {
                acc[groupIndex] = [];
            }

            acc[groupIndex].push(currentValue);

            return acc;
        }, []);
    }

    // Export types are missing for react-maskinput lib
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static reformatMaskedInput(params: any) {
        const regex = Regex.decimalRegex;

        const prevValue = (params.visibleValue ?? params.value).replace(regex, ',') as string;
        const inputValue = (params.input ?? '') as string;

        params.visibleValue = (
            prevValue.substring(0, params.selection.start) +
            inputValue +
            prevValue.substring(params.selection.end)
        ).replace(regex, ',');

        params.selection.start = params.visibleValue.length;
        params.selection.end = params.visibleValue.length;

        return params;
    }
}
