import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { SessionsVisualStore } from '../stores';
import { ApplicationSession } from '../../common/services/types';
import { Tooltip, Table, Card, Col, Row } from 'antd';
import { generateDocumentIcon } from '../../common/components/DocumentIconRenderer';
import Search from 'antd/lib/input/Search';
import { Utils } from '../../common/misc/Utils';
import { hasPermission, HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import authContext from '../../authorization/AuthContext';
import { useNavigate } from 'react-router';

type Props = {
    store: SessionsVisualStore
};

export const SessionsList: React.FC<Props> = ({ store }) => {
    const navigate = useNavigate();
    const authCtx = React.useContext(authContext);
    React.useEffect(() => {
        const loadForCurrentUserOnly = hasPermission(authCtx.permissions, AppPermissions.CanSeeAnalysisPagePersonal) 
            && !hasPermission(authCtx.permissions, AppPermissions.CanSeeAnalysisPageAll);
        store.loadApplicationSessions(loadForCurrentUserOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [store]);

    const generateStatusIcon = (error: string | null, state: string | null) => {
        if (error) {
            return (
                <Tooltip title={error}>
                    <span data-id-name="Status" className="closed-session-status" style={{background: '#F5222D', fontSize: 11}}>Failed</span>
                </Tooltip>
            );
        }
        switch (state) {
        case 'Finished':
            return (
                <span data-id-name="Status" className="closed-session-status" style={{background: '#9BA0AA', fontSize: 11}}>Completed</span>
            );
        case 'Archived':
            return (
                <span data-id-name="Status" className="closed-session-status" style={{background: '#FA8C16', fontSize: 11}}>Please review</span>
            );
        default: return;
        }
    };

    const handleReloadClick = (session: ApplicationSession) => {
        navigate('/analysis');
        store.startSessionFromExisting(session);
    };

    const generateActionButtons = (session: ApplicationSession) => {
        return (
            <div className="closed-session-operation">
                <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments}>
                    {session.isReloadable !== false 
                        ? (
                            <Tooltip title="Start from scratch">
                                <i className={'alpha-icon xs reload'} onClick={() => handleReloadClick(session)}/>
                            </Tooltip>) 
                        : null}
                </HasPermission>
                
                <Tooltip title="Switch to Analysis form">
                    <i className={'alpha-icon xs session-switch'} onClick={() => switchToSession(session)} />
                </Tooltip>
            </div>
        );
    };

    const formatDate = (date: string | null) => {
        if (date) {
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(new Date(date));
        } else {
            return '';
        }
    };

    const compareDateStrings = (d1: string, d2: string) => {
        return new Date(d1) > new Date(d2) ? -1 : new Date(d1) < new Date(d2) ? 1 : 0;
    };

    const switchToSession = (session: ApplicationSession) => {
        store.switchToSession(session);
    };

    const columns = [
        {
            title: 'Document',
            render: (a: ApplicationSession) => (
                <div className="label-with-icon archive-doc-name">
                    {generateDocumentIcon(a.packageName, !!a.packageSetId)} 
                    <Tooltip title={a.packageName}>
                        <span data-id-name="Package">{a.packageName}</span>
                    </Tooltip>
                </div>)
        }, {
            title: 'Application',
            render: (a: ApplicationSession) => (<span data-id-name="Application">{a.applicationName}</span>)
        }, {
            title: 'Project',
            render: (a: ApplicationSession) => (<span data-id-name="Project">{a.projectName}</span>)
        }, {
            title: 'Created By',
            render: (a: ApplicationSession) => (<span data-id-name="User">{store.allUsers[a.userId!]}</span>)
        }, {
            title: 'Created',
            // eslint-disable-next-line max-len
            render: (a: ApplicationSession) => (<span data-timestamp={Utils.dateStringToIso(formatDate(a.created))} data-id-name="Created">{Utils.formatDateStringShort(a.created, false, true)}</span>)
        }, {
            title: 'Updated By',
            render: (a: ApplicationSession) => (<span data-id-name="UpdatedBy">{a.updatedBy ? store.allUsers[a.updatedBy] || 'System' : ''}</span>)
        }, {
            title: 'Updated',
            // eslint-disable-next-line max-len
            render: (a: ApplicationSession) => (<span data-timestamp={Utils.dateStringToIso(formatDate(a.updated))} data-id-name="Updated">{Utils.formatDateStringShort(a.updated, false, true)}</span>)
        },{
            title: 'Status',
            render: (a: ApplicationSession) => generateStatusIcon(a.error, a.state),
            width: '95px'
        }, {
            render: (a: ApplicationSession) => generateActionButtons(a),
            width: '95px'
        }];

    return (
        <HasPermission permissionClaim={AppPermissions.CanAccessArchive}>
            <Row style={{height: 40, marginBottom: 30}} className="session-archive-header">
                <Col span={2}>
                    <h1 style={{fontSize: 34}}>Archive</h1>
                </Col>
                <Col span={22} style={{ textAlign: 'right', paddingTop: 16 }} data-id="archive-search-input-wrapper">
                    <Search 
                        style={{marginLeft: 20, marginTop: -5}}
                        placeholder="Search..." 
                        onChange={(e) => store.setSearchTerm(e.target.value)}
                        enterButton
                        maxLength={150}
                        allowClear
                        className="alpha-search-input no-search-btn ghost"
                        prefix={<i className="alpha-icon xs search" style={{marginRight: 10}}/>}
                    />
                </Col>
            </Row>
            <Card style={{border: 'none'}} className="content-card table-content">    
                <Observer>
                    {() => (
                        <Table 
                            data-id="alpha-archive-table"
                            className="alpha-table no-border-top"
                            rowKey={r => r.id}
                            onRow={(record) => {
                                return {
                                    'data-id': record.id,
                                    id: record.id
                                };
                            }}
                            pagination={{defaultPageSize: 15}}
                            columns={columns} 
                            tableLayout='fixed'
                            dataSource={store.filteredSessions.slice().sort((a1, a2) => compareDateStrings(a1.created, a2.created))} 
                        />
                    )}
                </Observer>
            </Card>
        </HasPermission>
    );
};

export default observer(SessionsList);
