import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';
import { GenericSelect } from '../../components';

const config = {
    label: 'Loss Entry Calculation Method'
};

const LossEntryCalculationMethodInput: InputWithSource = ({ input }) => (
    <GenericSelect label={config.label} input={input} allowClear />
);

LossEntryCalculationMethodInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(LossEntryCalculationMethodInput);
