import { action, computed, makeObservable } from 'mobx';
import { BlazorAppRuntimeResponse } from '../types';
import { TabModel } from '../../common/types/TabModel';
import { IotaSessionsStore } from '../../common/stores';
import { CustomRuntimeInput, CustomSessionRuntimeData } from '../../custom_shared/types';
import SessionStoreBase from '../../custom_shared/stores/SessionStoreBase';
import RuntimeDataStore from '../../custom_shared/stores/RuntimeDataStore';
import { IotaSessionResult } from '../../common/services/types';

enum Actions {
    UpdateSession = 'UpdateSession',
    FinishSession = 'FinishSession'
}

export default class BlazorAppSessionStore extends SessionStoreBase<BlazorAppRuntimeResponse> {
    readonly runtimeDataStore = new RuntimeDataStore();

    constructor(tab: TabModel, iotaSessionsStore: IotaSessionsStore) {
        super(tab, iotaSessionsStore);

        makeObservable(this, {
            initialInputs: computed,
            runtimeData: computed,
            handleSessionUpdate: action.bound,
        });

        iotaSessionsStore.iotaSessionChanges.subscribe(this.handleSessionUpdate);
    }

    async handleSessionUpdate(result: IotaSessionResult) {
        if (result.sessionId === this.sessionId) {
            this.setIsLoading(result.inProgress);
        }
    }

    async updateSession() {
        await this.continueSession(Actions.UpdateSession, this.runtimeDataStore.getValues());
    }

    async finishSession() {
        await this.continueSession(Actions.FinishSession, this.runtimeDataStore.getValues());
    }

    get initialInputs(): CustomRuntimeInput[] {
        return this.response ? this.response.initialInputs : [];
    }

    get runtimeData(): CustomSessionRuntimeData {
        return this.response ? this.response.runtimeData : {};
    }
}