import { LayersWithSectionsTabDto } from '../types';
import { TabBaseModel, LayerModel, SourceModelBase } from '.';

export default class LayersWithSectionsTabModel extends TabBaseModel {
    readonly numberOfLayers: number;

    readonly numberOfSections: number;

    readonly layers: LayerModel[] = [];

    constructor(data: LayersWithSectionsTabDto, sources: SourceModelBase[]) {
        super(data);

        this.numberOfLayers = data.numberOfLayers;
        this.numberOfSections = data.numberOfSections;
        this.layers = data.layers.map(layer => new LayerModel(this, layer, sources));
    }

    get inputs() {
        return this.layers.flatMap(layer => layer.sections.flatMap(section => section.inputs));
    }

    get dto(): LayersWithSectionsTabDto {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            numberOfLayers: this.numberOfLayers,
            numberOfSections: this.numberOfSections,
            layers: this.layers.map(layer => layer.dto)
        };
    }
}
