import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomPercentageInputWrapper, InputActions, CustomFormItem } from '..';
import { InputModel } from '../../models';
import { InputWithMeta } from '../../componentTypes';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModel
}

const GenericPercentageInput: InputWithMeta<Props> = ({ label, input }) => {
    return (
        <CustomFormItem name={input.id} initialValue={input.value ?? null}>
            <CustomPercentageInputWrapper input={input} label={label} topRightNode={<InputActions input={input} />} />
        </CustomFormItem>
    );
};

GenericPercentageInput.meta = {
    inputType: InputModel.name
};

export default observer(GenericPercentageInput);
