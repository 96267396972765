import { makeObservable, computed } from 'mobx';
import { ContractIngestionAppRuntimeDto } from '../types';
import { TabModel } from '../../common/types/TabModel';
import { IotaSessionsStore } from '../../common/stores';
import { SessionStoreBase } from '../../custom_shared/stores';

export default class SessionStore extends SessionStoreBase<ContractIngestionAppRuntimeDto> {
    constructor(tab: TabModel, iotaSessionsStore: IotaSessionsStore) {
        super(tab, iotaSessionsStore);

        makeObservable(this, {
            showLoader: computed
        });
    }

    get showLoader() {
        return this.isLoading || this.inProgress;
    }

    dispose() {
        this.disposeReactions();
    }
}
