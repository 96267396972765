import { observable, action, reaction, runInAction, computed, makeObservable } from 'mobx';
import { FileOperationsStore, WorkflowInstanceStore, ProjectsStore, TabsStore } from '../../common/stores';
import { PreviewsVisualStore } from '.';
import { ImagePreviewSize } from '../../documents/types/types';
import { SessionsVisualStore } from '../../sessions/stores';
import { types } from '../../common/services';
import { PackageListItemType, SessionStates } from '../../common/services/types';

export default class AnalyzeVisualStore {  
    showProgressModal: boolean = false;

    processedPackageName: string = '';

    processedPackageId: string | undefined = '';
    
    currentSessionId: string | undefined = undefined;

    runningSessionIds: string[] = [];

    sessionToHighlight: string | null = null;
    
    get recentFinishedSessions() {
        if (this.sessionsStore.applicationSessions) {
            return this.sessionsStore.applicationSessions
                .filter(a => a.state === 'Finished' || a.state === 'Aborted')
                .sort((a1, a2) => this.compareDateStrings(a1.updated, a2.updated))
                .slice(0, 5);
        } else {
            return [];
        }
    }

    get allSessions() {
        return this.sessionsStore.applicationSessions;
    }

    get openSessions() {
        if (this.sessionsStore.applicationSessions) {
            return this.sessionsStore.applicationSessions
                .filter(a => a.state && a.state !== 'Finished' && a.state !== 'Aborted' && a.state !== 'Archived')
                .sort((a, b ) => this.compareDateStrings(a.updated, b.updated));
        } else {
            return [];
        }
    }

    get currentSessionInProgress() {
        return this.openSessions.find(s => s.id === this.workflowInstanceStore.sessionId);
    }

    get sessionsLoading() {
        return this.sessionsStore.sessionsLoading;
    }

    get projectsLoading() {
        return this.projectsStore.isLoading;
    }

    constructor(
        private sessionsStore: SessionsVisualStore,
        private workflowInstanceStore: WorkflowInstanceStore,
        private projectsStore: ProjectsStore,
        private previewsStore: PreviewsVisualStore,
        private tabsStore: TabsStore,
        private fileStore: FileOperationsStore) {
        makeObservable(this, {
            showProgressModal: observable,
            processedPackageName: observable,
            processedPackageId: observable,
            currentSessionId: observable,
            runningSessionIds: observable,
            sessionToHighlight: observable,
            recentFinishedSessions: computed,
            openSessions: computed,
            currentSessionInProgress: computed,
            sessionsLoading: computed,
            projectsLoading: computed,
            setCurrentSessionId: action.bound,
            addRunningSessionId: action.bound,
            removeRunningSessionId: action.bound,
            setShowProgressModal: action.bound,
            setProcessedPackageName: action.bound,
            setProcessedPackageId: action.bound,
            getPreviewForCurrentRunningSession: action.bound,
            analyzePackage: action.bound,
            analyzeMultiplePackages: action.bound,
            openProgressModalForSession: action.bound,
            moveSessionToArchive: action.bound,
            startSessionFromExisting: action.bound,
            switchToSession: action.bound,
            loadSessions: action.bound,
            setSessionToHighlight: action.bound
        });

        reaction(() => this.openSessions, () => {
            this.runningSessionIds.forEach(id => { 
                this.handleSessionStateChange(id); 
            });
        });

        reaction(() => this.fileStore.isSessionProgressDialogVisible, p => {
            if (p) {
                tabsStore.resetSelection();
                this.setShowProgressModal(true);
            }
        });

        reaction(() => this.tabsStore.sessionTabReloading, sessionData => {
            if (sessionData) {
                this.setCurrentSessionId(sessionData.sessionId);
                this.setShowProgressModal(true);
                this.setProcessedPackageName(sessionData.packageName);
                this.setProcessedPackageId(sessionData.packageId);
                this.addRunningSessionId(sessionData.sessionId);
            }
        });

        this.projectsStore.sessionChanges.subscribe(session => {
            if (session.state === 'Aborted') {
                this.setSessionToHighlight(session.id);
            }
        });
    }

    setSessionToHighlight(sessionToHighlight: string | null) {
        this.sessionToHighlight = sessionToHighlight;
    }

    setCurrentSessionId(id: string | undefined) {
        this.currentSessionId = id;
    }

    addRunningSessionId(id: string) {
        this.runningSessionIds.push(id);
    }

    removeRunningSessionId(id: string) {
        this.runningSessionIds = this.runningSessionIds.filter(i => i !== id);
    }

    setShowProgressModal(show: boolean) {
        if (!show) {
            this.fileStore.setIsSessionProgressDialogVisible(false);
        }
        this.showProgressModal = show;
    }

    setProcessedPackageName(name: string) {
        this.processedPackageName = name;
    }

    setProcessedPackageId(id: string | undefined) {
        this.processedPackageId = id;
    }

    async getPreviewForCurrentRunningSession() {
        if (this.processedPackageId && !this.previewsStore.largePreviewUrls[this.processedPackageId]) {
            await this.previewsStore.getPreviews(this.processedPackageId, ImagePreviewSize.Large);
        }
    }
    
    async analyzePackage(
        pkgId: string,
        appId: string,
        pkgName: string,
        extension: string,
        type: PackageListItemType = PackageListItemType.Package,
        appName: string = ''
    ) {
        this.setShowProgressModal(true);
        this.projectsStore.setCurrentProject(this.projectsStore.selectedPackageProject);
        this.setProcessedPackageName(pkgName);
        this.setProcessedPackageId(pkgId);
        const callback = (sessionId: string) => {
            this.setCurrentSessionId(sessionId); 
            this.addRunningSessionId(sessionId); 
        };
        if (type === PackageListItemType.Package) {
            await this.workflowInstanceStore.startSession(pkgId, appId, pkgName, extension, callback);
        } else if (type === PackageListItemType.PackageSet) {
            await this.workflowInstanceStore.startSessionForPackageSet(pkgId, appId, extension, pkgName, callback);
        }
        
        this.workflowInstanceStore.setApplicationName(appName);
    }

    async analyzeMultiplePackages(
        pkgIds: string[],
        appId: string,
        pkgName: string,
        extension: string,
        appName: string = ''
    ) {
        this.setShowProgressModal(true);
        this.projectsStore.setCurrentProject(this.projectsStore.selectedPackageProject);
        const selectedPackageNames = this.projectsStore.packageListItems.filter(p => pkgIds.includes(p.id)).map(p => p.fileName);
        const pkgNamesString = selectedPackageNames.join(', ');
        this.setProcessedPackageName(pkgNamesString);
        this.setProcessedPackageId(pkgIds[0]);
        await this.workflowInstanceStore.startSessionForMultiplePackages(pkgIds, appId, extension, (sessionId: string) => {
            this.setCurrentSessionId(sessionId); 
            this.addRunningSessionId(sessionId); 
        });
        this.workflowInstanceStore.setApplicationName(appName);
    }

    openProgressModalForSession(sessionModel: unknown) {
        let session = sessionModel as types.ApplicationSession;
        this.setProcessedPackageName(session.packageName);
        this.setProcessedPackageId(session.packageId ?? session.packageSetId);
        this.setCurrentSessionId(session.id);
        this.projectsStore.setCurrentProject(session.projectId);
        this.addRunningSessionId(session.id);
        runInAction(() => {
            this.workflowInstanceStore.sessionId = session.id; 
        });
        this.setShowProgressModal(true);
    }

    async moveSessionToArchive(sessionId: string) {
        const resp = await this.workflowInstanceStore.updateSession(sessionId, 'Archived' as SessionStates);
        resp.map(() => this.sessionsStore.moveSessionToArchive(sessionId));
    }

    startSessionFromExisting(session: types.ApplicationSession) {
        if (session.packageId) {
            this.workflowInstanceStore.startSession(session.packageId, session.applicationDefinitionId, session.packageName, session.applicationExtension);
        } else if (session.packageSetId) {
            this.workflowInstanceStore.startSessionForPackageSet(session.packageSetId, session.applicationDefinitionId, session.applicationExtension, session.packageName);
        }
    }

    switchToSession(session: types.ApplicationSession) {
        this.sessionsStore.switchToSession(session);
    }

    async loadSessions(currentUserOnly: boolean = false) {
        await this.sessionsStore.loadApplicationSessions(currentUserOnly);
    }

    private compareDateStrings = (d1: string, d2: string) => {
        return new Date(d1) > new Date(d2) ? -1 : new Date(d1) < new Date(d2) ? 1 : 0;
    };

    private handleSessionStateChange = (sessionId: string) => {
        let currentSession = this.openSessions.find(s => s.id === sessionId);
        if (!currentSession) {
            currentSession = this.recentFinishedSessions.find(s => s.id === this.currentSessionId);
        }

        const sessionProcessed = currentSession && ['Finished', 'WaitingAction'].some(s => s === currentSession?.state);
        if (currentSession?.state === 'Aborted') {
            this.setShowProgressModal(false);
            this.removeRunningSessionId(sessionId);
        }

        if (this.runningSessionIds && this.runningSessionIds.length && sessionProcessed) {
            let session = this.sessionsStore.applicationSessions.find(s => s.id === sessionId);
            // if (this.tabsStore.sessionTabReloading) { 
            //     session = this.sessionsStore.applicationSessions.find(s => s.id === this.tabsStore.sessionTabReloading?.sessionId);
            // }
            if (session) {
                const makeNewTabFocused = this.showProgressModal && sessionId === this.currentSessionId;
                this.tabsStore.setSessionTabReloading(undefined);
                this.workflowInstanceStore.setApplicationName(session.applicationName);
                this.tabsStore.addTab({
                    id: session.id,
                    title: session.packageName,
                    isSelected: makeNewTabFocused,
                    type: session.isBulkSession ? 'multidocument' : 'document',
                    metadata: {
                        packageId: session.packageId,
                        packageName: session.packageName,
                        sessionId: session.id,
                        uiIsLoading: true,
                        dynamicUI: undefined,
                        sessionError: session.error,
                        sessionState: session.state,
                        runtimeSessionId: session.runtimeSessionId,
                        packageSetId: session.packageSetId,
                        projectName: session.projectName,
                        projectId: session.projectId,
                        isReloadable: session.isReloadable,
                        customUI: session.capabilities?.customUI,
                        isBulk: session.isBulkSession,
                        applicationData: {
                            appName: session.applicationName,
                            appId: session.applicationDefinitionId,
                            appExtension: session.applicationExtension,
                            appSettings: session.applicationSettings,
                            iotaApplication: session.iotaApplication
                        }
                    }
                });
                if (makeNewTabFocused) {
                    this.setShowProgressModal(false);
                    this.setCurrentSessionId(undefined);
                }
                this.removeRunningSessionId(session.id);
            }
        }
    };
}