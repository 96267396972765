import { BasicTabDto } from '../types';
import { TabBaseModel, SourceModelBase, InputBaseModel } from '.';
import { InputFactory } from '../misc';

export default class BasicTabModel extends TabBaseModel {
    readonly inputs: InputBaseModel[] = [];

    constructor(data: BasicTabDto, sources: SourceModelBase[]) {
        super(data);

        this.inputs = data.inputs.map(input => InputFactory.createInput(this, input, sources));
    }

    get dto(): BasicTabDto {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            inputs: this.inputs.map(input => input.dto)
        };
    }
}
