import * as React from 'react';
import { InputBaseModel } from '../models';
import { useAppStore } from '../stores';

export default (input: InputBaseModel, containerRef: React.RefObject<HTMLDivElement> | null) => {
    const { documentVisualStore } = useAppStore();

    const [isHighlighted, setIsHighlighted] = React.useState(false);

    React.useEffect(() => {
        const subject = documentVisualStore.highlightedInputIdSubject.subscribe(inputId =>
            setIsHighlighted(inputId === input.id)
        );

        return () => subject.unsubscribe();
    }, [input, documentVisualStore.highlightedInputIdSubject]);

    React.useEffect(() => {
        if (!isHighlighted) {
            return;
        }

        const resetHighlightDelayTime = () => documentVisualStore.setInputHighlightDelay(0);

        const timeout = setTimeout(() => {
            if (!containerRef || !containerRef.current) {
                return resetHighlightDelayTime();
            }

            containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

            resetHighlightDelayTime();
        }, documentVisualStore.inputHighlightDelay);

        return () => clearTimeout(timeout);
    }, [isHighlighted, documentVisualStore, containerRef]);

    return {
        containerStyle: isHighlighted ? { boxShadow: '0px 0px 1px 2px #83acde' } : {}
    };
};
