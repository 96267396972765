import { LayerDto } from '../types';
import { SectionModel, SourceModelBase, TabBaseModel } from '.';

export default class LayerModel {
    readonly name: string;

    readonly sections: SectionModel[] = [];

    constructor(tab: TabBaseModel, data: LayerDto, sources: SourceModelBase[]) {
        this.name = data.name;
        this.sections = data.sections.map(section => new SectionModel(tab, section, sources));
    }

    get dto(): LayerDto {
        return {
            name: this.name,
            sections: this.sections.map(section => section.dto)
        };
    }
}
