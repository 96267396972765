import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomCheckboxWrapper, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Create program'
};

const CreateProgramInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem name={input.id} initialValue={input.value ?? false} valuePropName="checked">
            <CustomCheckboxWrapper label={config.label} input={input} />
        </CustomFormItem>
    );
};

CreateProgramInput.meta = {
    inputType: InputModel.name
};

export default observer(CreateProgramInput);
