import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import { StyleUtils } from '../../misc';
import { TabProps } from '../../componentTypes';
import {
    EstimatedPremiumIncomeInput,
    MinimalPremiumInput,
    DepositPremiumInput,
    SubjectPremiumIncomeInput,
    PremiumRateInput,
    PremiumTypeInput,
    TotalGrossPremiumInput
} from '../../tab_inputs/PremiumsTabInputs';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    EstimatedPremiumIncome: 'EstimatedPremiumIncome',
    MinimalPremium: 'MinimalPremium',
    DepositPremium: 'DepositPremium',
    SubjectPremiumIncome: 'SubjectPremiumIncome',
    PremiumRate: 'PremiumRate',
    PremiumType: 'PremiumType',
    TotalGrossPremium: 'TotalGrossPremium'
} as const;

const InputComponents = {
    [InputIds.EstimatedPremiumIncome]: EstimatedPremiumIncomeInput,
    [InputIds.MinimalPremium]: MinimalPremiumInput,
    [InputIds.DepositPremium]: DepositPremiumInput,
    [InputIds.SubjectPremiumIncome]: SubjectPremiumIncomeInput,
    [InputIds.PremiumRate]: PremiumRateInput,
    [InputIds.PremiumType]: PremiumTypeInput,
    [InputIds.TotalGrossPremium]: TotalGrossPremiumInput
} as const;

const PremiumsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, onValuesChange } = useTabForm(tab, addFormRef);

    const componentRows = React.useMemo(
        () =>
            Utils.groupArray(tab.inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        [tab.inputs]
    );

    return (
        <Form id={tab.id} form={form} onValuesChange={onValuesChange}>
            <TabCollapse tab={tab}>
                {componentRows.map((componentRow, index) => (
                    <Row key={index} gutter={10}>
                        {componentRow.map(component => (
                            <Col key={component.key} style={StyleUtils.getTabColumnStyle(25)}>
                                {component}
                            </Col>
                        ))}
                    </Row>
                ))}
            </TabCollapse>
        </Form>
    );
};

export default observer(PremiumsTabRenderer);
