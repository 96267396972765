import { RuntimeDataStore } from '../../custom_shared/stores';
import SessionStore from './SessionStore';
import TabStore from './TabStore';

const Actions = {
    ChangeBusinessType: 'changeBusinessType',
    FinishSession: 'finishSession',
    AddLimit: 'addLimit',
    AddDeduction: 'addDeduction',
    RebuildTabs: 'rebuildTabs'
} as const;

const Params = {
    ActionParams: 'actionParams'
} as const;

export default class ActionStore {
    constructor(
        private readonly tabStore: TabStore,
        private readonly sessionStore: SessionStore,
        private readonly runtimeDataStore: RuntimeDataStore
    ) {}

    async changeBusinessType() {
        await this.sessionStore.continueSession(Actions.ChangeBusinessType, this.getRuntimeData());
    }

    async finishSession() {
        await this.sessionStore.continueSession(Actions.FinishSession, this.getRuntimeData());
    }

    async addLimit(params: Record<string, unknown>) {
        this.runtimeDataStore.add(Params.ActionParams, params);

        await this.sessionStore.continueSession(Actions.AddLimit, this.getRuntimeData());
    }

    async addDeduction(params: Record<string, unknown>) {
        this.runtimeDataStore.add(Params.ActionParams, params);

        await this.sessionStore.continueSession(Actions.AddDeduction, this.getRuntimeData());
    }

    async rebuildTabs() {
        await this.sessionStore.continueSession(Actions.RebuildTabs, this.getRuntimeData());
    }

    private getRuntimeData() {
        this.tabStore.tabs.forEach(tab => this.runtimeDataStore.add(tab.id, JSON.stringify(tab.dto)));

        return this.runtimeDataStore.getValues();
    }
}
