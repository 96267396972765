import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomMaskedInputWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { Utils, Validators } from '../../misc';

const config = {
    label: 'Liability'
};

const LiabilityInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem
            name={input.id}
            initialValue={input.value ?? null}
            customValidator={(...args) => Validators.unlimitedFieldValidator(...args)}
            getValueFromEvent={event => event.target.value.replace(/,/g, '')}
            messageStyle={{ width: 305 }}
        >
            <CustomMaskedInputWrapper
                input={input}
                label={config.label}
                topRightNode={<InputActions input={input} />}
                reformat={params => Utils.reformatMaskedInput(params)}
            />
        </CustomFormItem>
    );
};

LiabilityInput.meta = {
    inputType: InputModel.name
};

export default observer(LiabilityInput);
