import * as React from 'react';
import { debounce } from 'lodash';

export default (containerRef: React.RefObject<HTMLDivElement>, selector: string) => {
    const [hasSelectedElement, setHasSelectedElement] = React.useState(false);

    const updateSelectedElementStatus = React.useMemo(
        () =>
            debounce(() => {
                if (containerRef.current) {
                    setHasSelectedElement(!!containerRef.current.querySelector(selector));
                }
            }, 100),
        [containerRef, selector]
    );

    React.useEffect(() => {
        updateSelectedElementStatus();

        const observer = new MutationObserver(updateSelectedElementStatus);

        if (containerRef.current) {
            observer.observe(containerRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
                characterData: true
            });
        }

        return () => {
            observer.disconnect();
            updateSelectedElementStatus.cancel();
        };
    }, [containerRef, updateSelectedElementStatus]);

    return { hasSelectedElement };
};
