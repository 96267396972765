import { SourceModelBase } from '.';
import { CustomStaticSource, CustomSourceData } from '../../custom_shared/types';

export default class StaticSourceModel extends SourceModelBase {
    readonly data: CustomSourceData[];

    constructor(data: CustomStaticSource) {
        super(data);

        this.data = data.data ?? [];
    }

    async getSourceOptions() {
        return this.mapData(this.data);
    }
}
