import { action, computed, makeObservable, observable } from 'mobx';
import { TabBaseModel } from '.';
import { ContractsIngestionRuntimeInputDto } from '../types';

export default abstract class InputBaseModel {
    readonly id: string;
    readonly parentInputId: string | null;
    readonly initialValue: unknown;
    readonly meta: Record<string, unknown>;
    readonly sourceId: string | null;

    value: unknown;

    constructor(private readonly tab: TabBaseModel, data: ContractsIngestionRuntimeInputDto) {
        this.id = data.id;
        this.parentInputId = data.parentInputId;
        this.value = data.value;
        this.initialValue = data.value;
        this.meta = data.meta;
        this.sourceId = data.sourceId;

        makeObservable(this, {
            value: observable,
            setValue: action.bound,
            parentInput: computed,
            childInput: computed
        });
    }

    setValue(value: unknown) {
        this.value = value;
    }

    get parentInput() {
        return this.tab.inputs.find(i => i.id === this.parentInputId);
    }

    get childInput() {
        return this.tab.inputs.find(i => i.parentInputId === this.id);
    }

    get dto(): ContractsIngestionRuntimeInputDto {
        return {
            id: this.id,
            parentInputId: this.parentInputId,
            value: this.value,
            meta: this.meta,
            sourceId: this.sourceId
        };
    }
}
