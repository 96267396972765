import { TabBaseModel, InputModel, InputModelWithSource, SourceModelBase } from '../models';
import { ContractsIngestionRuntimeInputDto } from '../types';

export default class InputFactory {
    static createInput(tab: TabBaseModel, input: ContractsIngestionRuntimeInputDto, sources: SourceModelBase[]) {
        const source = input.sourceId ? sources.find(s => s.id === input.sourceId) : null;

        if (source) {
            return new InputModelWithSource(tab, input, source);
        }

        return new InputModel(tab, input);
    }
}
