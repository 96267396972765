import { observable, action, runInAction, makeObservable } from 'mobx';
import { TabModel } from '../../common/types/TabModel';
import { IotaSessionsStore } from '../../common/stores';
import {
    CustomSessionChangeResult,
    CustomSessionState,
    CustomSessionResponseBase,
    CustomSessionRuntimeData
} from '../types';
import { Disposer } from '../../custom_shared/misc';
import CustomSessionService from '../services/CustomSessionService';

export default abstract class SessionStoreBase<TResponse extends CustomSessionResponseBase> extends Disposer {
    isLoading: boolean = false;

    inProgress: boolean = false;

    isFinished: boolean = false;

    response: TResponse | null = null;

    readonly sessionService = new CustomSessionService();

    constructor(protected readonly tab: TabModel, protected readonly iotaSessionsStore: IotaSessionsStore) {
        super();

        makeObservable(this, {
            isLoading: observable,
            inProgress: observable,
            isFinished: observable,
            response: observable,
            setIsLoading: action,
            getSession: action,
            continueSession: action
        });

        this.iotaSessionsStore.iotaSessionChanges.subscribe((session: CustomSessionChangeResult<TResponse>) => {
            if (this.sessionId === session.sessionId) {
                this.setData(session);
            }
        });
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setData(session: CustomSessionState<TResponse> | CustomSessionChangeResult<TResponse>) {
        runInAction(() => {
            this.inProgress = session.inProgress;
            this.isFinished = !session.response.actions.length;
            this.response = session.response;
        });
    }

    async getSession() {
        try {
            this.setIsLoading(true);

            const response = await this.sessionService.getSessionState<TResponse>(this.sessionExtension, this.sessionId);

            if (response.isOk()) {
                response.map(session => this.setData(session));
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async continueSession(actionId: string, runtimeData: CustomSessionRuntimeData) {
        try {
            this.setIsLoading(true);

            await this.sessionService.continueSession(this.sessionExtension, this.sessionId, actionId, runtimeData);
        } catch (err) {
            console.log(err);
        } finally {
            this.setIsLoading(false);
        }
    }

    get sessionId() {
        return this.tab.metadata.sessionId as string;
    }

    get sessionExtension() {
        return this.tab.metadata.applicationData.appExtension as string;
    }
}
