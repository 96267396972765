import * as React from 'react';
import { Tabs, FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { TabBaseModel } from '../../models';
import { TabRendererRegistry } from '..';
import './TabsRenderer.less';
import '../../../custom_shared/styles/common.less';

interface Props {
    addFormRef: (id: string, form: FormInstance) => void
}

const TabsRenderer: React.FC<Props> = ({ addFormRef }) => {
    const { tabStore, documentVisualStore, documentPreviewStore } = useAppStore();

    React.useEffect(() => {
        const subject = documentVisualStore.highlightedInputIdSubject.subscribe(inputId => {
            const tab = tabStore.getTabByInputId(inputId);

            if (!tab) {
                return;
            }

            if (!tab.expanded) {
                documentVisualStore.setInputHighlightDelay(500);
                tab.setExpanded(true);
            }

            tabStore.setActiveTabId(tab.id);
        });

        return () => subject.unsubscribe();
    }, [tabStore, documentVisualStore, documentVisualStore.highlightedInputIdSubject]);

    const renderTab = (tab: TabBaseModel) => {
        const TabRenderer = TabRendererRegistry[tab.id];

        if (!TabRenderer) {
            console.error(`Tab '${tab.id}' is not registered`);
        }

        return TabRenderer ? <TabRenderer tab={tab} addFormRef={addFormRef} /> : null;
    };

    const tabPaneStyle: React.CSSProperties = React.useMemo(() => {
        if (documentPreviewStore.previewPosition === 'fullScreen') {
            return {
                height: '100%'
            };
        }

        let offset = documentPreviewStore.expanded ? 55 : 45;

        return {
            height: `calc(100% - ${documentPreviewStore.bottomPreviewHeight - offset}px)`
        };
    }, [documentPreviewStore.expanded, documentPreviewStore.previewPosition, documentPreviewStore.bottomPreviewHeight]);

    return (
        <Tabs
            className="contract-ingestion-tabs-renderer"
            activeKey={tabStore.activeTabId}
            onChange={tabStore.setActiveTabId}
        >
            {tabStore.tabs.map(tab => (
                <Tabs.TabPane
                    className="contract-ingestion-tab-pane custom-scroll"
                    tab={<span className="tab-name-label">{tab.name}</span>}
                    key={tab.id}
                    style={tabPaneStyle}
                >
                    {renderTab(tab)}
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
};

export default observer(TabsRenderer);
