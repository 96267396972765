import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomDatePickerWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { Utils, ValidationMessages } from '../../misc';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Period start',
    required: true
};

const PeriodStartInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem
            name={input.id}
            initialValue={Utils.formatDateString(input.value) ?? null}
            required={config.required}
            message={ValidationMessages.requiredInput(config.label)}
        >
            <CustomDatePickerWrapper
                input={input}
                label={config.label}
                topRightNode={<InputActions input={input} />}
                required={config.required}
            />
        </CustomFormItem>
    );
};

PeriodStartInput.meta = {
    inputType: InputModel.name
};

export default observer(PeriodStartInput);
